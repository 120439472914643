import "./ImagePanel.css"
import { useState, useEffect } from "react";
import ApiRoute from "../ApiRoute/ApiRoute"
import ModImagePanel from "./ModImagePanel";

function ImagePanel({bearerToken})
{
    const [allImages, setAllImages] = useState({});
    const [reloadImage, setReloadImages] = useState(0);
    const [clickedImage, setIsClickedImage] = useState(null)
    useEffect(() => {
        const requestOptions =  {
            method : 'GET',
            headers : {
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':  'application/json'
            }
        }
        fetch(`${ApiRoute}/content`, requestOptions)
        .then((res) => {
            if (res.status !== 200)
                throw ("Status error");
            return res.json();
        })
        .then((data) => {setAllImages(data.image_content); console.log(data.image_content)})
        .catch((err) => {window.location.reload()})
    }, [reloadImage])
    return (
        <div className="baseImages" id="Images">
            {clickedImage && <ModImagePanel image={clickedImage} closeImage={setIsClickedImage} reloadImages={setReloadImages} bearerToken={bearerToken}/>}
            <h1 className="imageEditWelcome">Edition des images</h1>
            <h2 className="imageLittleText">Cliquez sur une image pour l'éditer</h2>
            <div className="gridImages">
                {
                    Object.keys(allImages).map((key) => (
                        <img key={key}  src={`${process.env.PUBLIC_URL}/images${allImages[key]}`} onClick={() => {setIsClickedImage({"id" : key, "path" : allImages[key]})}} className="oneImage"/>
                    ))
                }
            </div>
        </div>
    )
}

export default ImagePanel;