import "./TextEdit.css"
import ApiRoute from "../ApiRoute/ApiRoute"
import { useEffect, useState } from "react"


function TextEdit({bearerToken})
{
    const [allText, setAllText] = useState({});
    const [reloadText, setReloadText] = useState(0);
    useEffect(() => {
        const requestOptions =  {
            method : 'GET',
            headers : {
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type':  'application/json'
            }
        }
        fetch(`${ApiRoute}/content`, requestOptions)
        .then((res) => {
            if (res.status !== 200)
                throw ("Status error");
            return res.json();
        })
        .then((data) => setAllText(data.text_content))
        .catch((err) => {window.location.reload()})
    }, [reloadText])
    const handleChange = (param, e) => {
        var newText = {...allText}
        newText[param] = e.target.value;
        setAllText(newText);
    }
    const handleValidate = () => {
        const keys = Object.keys(allText);
        for (let i in keys) {
            const requestOptions =  {
                method : 'PUT',
                headers : {
                    'Authorization': `Bearer ${bearerToken}`,
                    'Content-Type':  'application/json'
                },
                body : JSON.stringify({id : keys[i], text_value : allText[keys[i]]})
            }
            fetch(`${ApiRoute}/text_content`, requestOptions)
            .then((res) => {
                if (res.status !== 200)
                    throw ("Status Error")
            })
            .catch((err) => {window.location.reload()})
        }
        setReloadText(reloadText + 1);
    }
    return (
        <div className="allTextEdit" id="Textes">
            <h1 className="textEditWelcome">Edition des textes</h1>
            <div className="textScrollableList">
                {
                    Object.keys(allText).map((key) => (
                        <input key={key} className="inputList" onChange={handleChange.bind(this, key)} value={allText[key]}></input>
                    ))
                }
            </div>
            <button className="buttonList" onClick={handleValidate}>Enregistrer vos modifications</button>
        </div>
    )
}

export default TextEdit;