import React, { useState, useEffect } from 'react'

function ImportText({file})
{
    const [retText, setRetText] = useState('')
    fetch(file)
    .then((r) => {r.text()
    .then(text => {setRetText(text);})});
    return (
        <div>
            {retText}
        </div>
    );
        
}

export default ImportText;