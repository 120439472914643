import './ContactPage.css'
import { useRef } from 'react';
import emailjs from "@emailjs/browser"
import { Link } from 'react-router-dom';

function ContactPage(props)
{
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
        .then((result) =>{
            console.log(result.text)
        }, (error) => {
            console.log(error.text)
        })
        e.target.reset();
    }
    return (
        <div id = {props.id} className="baseContactPage">
                <form ref={form} className={props.screenSize >= 850 ? "form" : 'formPhone'} onSubmit={sendEmail}>
                <h2 className='contactFormTitle'>Contactez moi</h2>
                    <p className={"contactP"} type="Votre nom"><input className={'contactInput'} name={"name_message"} placeholder="Nom Prénom"></input></p>
                    <p className={"contactP"} type="Votre adresse mail"><input className={'contactInput'} name={"from_name"} placeholder="example@gmail.com"></input></p>
                    <p className={"contactP"} type="Votre message"><textarea name="message" className={'contactTextArea'}  placeholder="Je vous contacte pour..."></textarea></p>
                <button className='contactButton'>Envoyer</button>
                </form>
                <div className='personnalInfosHandling'>
                    <p className="personnalInfosP">En soumettant ce formulaire facultatif, j'accepte que mes données personnelles soient utilisées pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire. Aucun autre traitement ne sera effectué avec mes informations. Pour connaître et exercer vos droits, notamment de retrait de votre consentement à l'utilisation de données collectés par ce formulaire, veuillez consultez notre <Link to={"/mentions_legales"}>Politique de confidentialité.</Link></p>
                </div>
        </div>
    )
}

export default ContactPage;