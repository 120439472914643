import "./Realizations.css"
import RealizationCard from "./RealizationCard";
import sfwImg from "./img/sfwSite.png"
import mySiteImg from "./img/MySitePicture.png"

function Realizations(props) 
{
    return (
            <div id = {props.id} className="backReal">
                <h1 className='realizationsTitle'>Mes Réalisations</h1>
                <div className="cards">
                    <RealizationCard title = {'Sfw Expertise'} backTitle={"Application web full-stack de gestion comptable"} 
                    backDescription={"Application réalisée pour un cabinet d'expertise comptable. Elle a été faite \
                    dans le cadre de la remise au goût du jour de l'un de leurs anciens logiciels. Je me suis occupé de tous les aspects de ce projet, du choix des technologies\
                    à la mise en place sur les serveurs de l'entreprise."} img={sfwImg} altImg={"SFW expertise webapp"}/>
                    <RealizationCard title = {'Ce site'} backTitle={"Un site vitrine en Reactjs"}
                    backDescription={"Ce site a été crée entièrement par moi et il est une vitrine de ce que je sais faire en terme de sites vitrines. Il a été crée lors du début de mon\
                    activité en free-lance."} img={mySiteImg} altImg={"Ce site"}/>
                </div>
            </div>
    )
}

export default Realizations;