import "./AdminMainPanel.css"
import ApiRoute from "../ApiRoute/ApiRoute"
import { useState } from "react";
import LoginPage from "../LoginPage/LoginPage";
import AdminNavBar from "../AdminNavBar/AdminNavBar";
import TextEdit from "../TextEdit/TextEdit";
import ImagePanel from "../ImagePanel/ImagePanel";

function AdminMainPanel()
{
    const [BearerToken, setBearerToken] = useState('');
    const [loginIsOpen, setLoginIsOpen] = useState(true)
    return (
        <>
            {loginIsOpen && <LoginPage ApiRoute={ApiRoute} closeLogin={setLoginIsOpen} setToken={setBearerToken}/>}
            {!loginIsOpen && <div className="mainPanel">
                <AdminNavBar />
                <div className="allContent">
                    <h1 className="welcomeText">Bienvenue dans le panneau de configuration de votre page</h1>
                    <TextEdit bearerToken={BearerToken} />
                    <ImagePanel bearerToken={BearerToken} />
                </div>
            </div>}
        </>
    )
}

export default AdminMainPanel;