import React from "react";
import "./ImagePanel.css";
import { useEffect, useState} from "react";
import ApiRoute from "../ApiRoute/ApiRoute";

function ModImagePanel({ image, closeImage, reloadImages, bearerToken }) {
    const handleCancelClick = () => {
        closeImage(null);
    };
    const [selectedFile, setSelectedFile] = useState(null);
    const [loaded, setLoaded] = useState(0);
    const handleSelectedFile = event => {
          setSelectedFile(event.target.files[0])
    }
    const handleUpload = async () => {
        const data = new FormData();
        data.append('file', selectedFile, selectedFile.name);
      
        try {
          const response = await fetch(`${ApiRoute}/image_content`, {
            method: 'PUT',
            body: data,
            headers : {'Authorization': `Bearer ${bearerToken}`,
            "Content-Type":"multipart/form-data"}
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          const responseData = await response.json();
      
          console.log(responseData);
          closeImage(null);
        } catch (error) {
          console.log('There has been a problem with your fetch operation:', error.message);
        }
      }
      
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
          document.body.style.overflow = "auto";
        };
      }, []);
    return (
        <div className="globalPanel">
            <h1 className="imageEditWelcome">Choisir une nouvelle image</h1>
            <h2 className="imageLittleText">Attention ! L'ancienne image va être écrasée</h2>
            <h2 className="imageLittleText">
                Faites aussi attention à la taille de votre image, une image avec une
                taille différente pourrait avoir un résultat non voulu
            </h2>
            <div className="gridImages">
                <img src={`${process.env.PUBLIC_URL}/images${image.path}`} className="oneImage"/>
                <input className="inputImage" type="file" accept="image/*" onChange={handleSelectedFile}/>
            </div>
            <div className="endButtons" >
                <button className="endButtonValid" onClick={handleUpload}>Valider</button>
                <button className="endButtonAnnul" type="button" onClick={handleCancelClick}>Annuler</button>
            </div>
        </div>
    );
}

export default ModImagePanel;
