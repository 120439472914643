import React, { useState, useEffect } from 'react'
import "./NavBar.css"
import { BiMenuAltLeft } from "react-icons/bi";
import { Link, animateScroll as scroll } from "react-scroll";
function getCurrentDimension()
{
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
}

function Panel({handleClosePanel, isPanelHidden})
{
    return (
        <div className={isPanelHidden ? 'leftPanel': 'leftPanel-hidden'}>
            <div>
            <Link activeClass="active" to="top" spy={true} smooth={true} offset={0} duration={500} onClick={handleClosePanel}>
                <button className='firstStackedButton'>Accueil</button>
            </Link>
            <Link activeClass="active" to="competences" spy={true} smooth={true} offset={0} duration={500} onClick={handleClosePanel}>
                <button className='stackedButton'>Mes compétences</button>
            </Link>
            <Link activeClass="active" to="realizations" spy={true} smooth={true} offset={0} duration={500} onClick={handleClosePanel}>
                <button className='stackedButton'>Mes réalisations</button>
            </Link>
            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500} onClick={handleClosePanel}>
                <button className='stackedButton'>Contact</button>
            </Link>
            </div>
        </div>
    )
}

function NavBar() {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [panelIsOpen, setPanelIsOpen] = useState(false);
    const [isPanelHidden, setIsPanelHidden] = useState(true);
    useEffect(() => {
        const updateDimension = () => {
          setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        setPanelIsOpen(false)
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
      }, [screenSize])
      const handleClosePanel = () => {
          setTimeout(() => {
            setPanelIsOpen(false);
          }, 300);
      }
    const handleOpenPanel = () => {
        setIsPanelHidden(!panelIsOpen)
        if (panelIsOpen)
            return handleClosePanel();
        setPanelIsOpen(true);
    }
    return (
    <div className='onTop' id={"top"}>
    <nav className="navigation">
    {
        screenSize.width >= 870 ?
        <a href="/" className="brand-name">
        Denni-Dev
        </a> : <button className="menuButton" onClick={handleOpenPanel}>
            <BiMenuAltLeft />
            </button> 
    }
    {
        screenSize.width >= 870 ? 
        <div
        className="navigation-menu">
        <ul>
            <li>
            <Link activeClass="active" to="top" spy={true} smooth={true} offset={0} duration={500}>
                Accueil
            </Link>
            </li>
            <li>
            <Link activeClass="active" to="competences" spy={true} smooth={true} offset={0} duration={500}>
                Mes Compétences
            </Link>
            </li>
            <li>
            <Link activeClass="active" to="realizations" spy={true} smooth={true} offset={0} duration={500}>
                Mes Réalisations
            </Link>
            </li>
            <li>
            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500}>
                Contact
            </Link>
            </li>
        </ul>
        </div> : ''
    }
  </nav>
  {panelIsOpen ? Panel({handleClosePanel, isPanelHidden}) : ''}
  </div>)
}

export default NavBar;