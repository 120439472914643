import React, { useState, useEffect } from 'react'
import logo from './Denni-Dev.png'
import "./WelcomePage.css"
import ImportText from '../../Administration/ImportText'
import buttonEmpty from "./buttonEmpty.png"
import buttonFill from "./buttonFill.png"
import { Link, animateScroll as scroll } from "react-scroll";

function WelcomePage(props) 
{
    const [actualPanel, setActualPanel] = useState(0);
    const [inAnimation, setInAnimation] = useState(false)
    const handleChangePanel = (nbPanel) => {
        if (nbPanel === actualPanel)
            return;
        setInAnimation(true)
        setTimeout(() =>{
            setActualPanel(nbPanel)
            setInAnimation(false)
        }, 150);
    }
    return (
        <div className='backPage'>
            <div hidden={actualPanel === 0 ? false : true} className={((props.screenSize.width >= 1600 ? 'myLogoOne' : 'myLogoTwo') + (!inAnimation ? '' : '-hidden'))}>
                    <img src={logo} alt="Logo Denni-Dev"  className={props.screenSize.width >= 1700 ? 'responsiveImgOne' : 'responsiveImgTwo'}/>
                    <h2 className={props.screenSize.width >= 1600 ? 'subTextOne' : 'subTextTwo'}><ImportText file="Text/WelcomePageHeader.txt"/></h2>
            </div>
            <div hidden={actualPanel === 1 ? false : true} className={((props.screenSize.width >= 1600 ? 'contactPanel' : 'contactPanelPhone') + (!inAnimation ? '' : '-hidden'))}>
                <h1 className={props.screenSize.width >= 1600 ? 'upTextOne' : 'upTextTwo'}>Me contacter ?</h1>
                <h2 className={props.screenSize.width >= 1600 ? 'subTextOne' : 'subTextTwo'}>Veuillez cliquer ici pour remplir le formulaire de contact</h2>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={300}>
                    <button className={props.screenSize.width >= 1600 ? 'button' : 'buttonOnPhone'}>Formulaire{props.screenSize.width >= 1600 ? <br></br> : ''} de contact</button>
                </Link>
            </div>
            <div className={((props.screenSize.width >= 1600 ? 'downButtons' : 'downButtonsPhone') + (''))}>
                <img className='buttonsPanel' onClick={() => {handleChangePanel(0)}} src={actualPanel === 1 ? buttonEmpty : buttonFill} alt="empty button"/>
                <img className='buttonsPanel' onClick={() => {handleChangePanel(1)}} src={actualPanel === 0 ? buttonEmpty : buttonFill} alt="empty button"/>
            </div>
        </div>
    )
}

export default WelcomePage;