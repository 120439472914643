import './App.css';
import NavBar from './NavBar/NavBar';
import WelcomePage from './Pages/WelcomePage/WelcomePage';
import CompetencesPage from './Pages/CompetencesPage/CompetencesPage';
import Realizations from './Pages/RealizationsPage/RealizationsPage';
import ContactPage from './Pages/ContactPage/ContactPage';
import React, { useState, useEffect } from 'react'
import GoUpButton from "./Pages/GoUpButton/GoUpButton"
import { Link } from "react-router-dom";
function getCurrentDimension()
{
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
}

function MainPage() {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  useEffect(() => {
      const updateDimension = () => {
        setScreenSize(getCurrentDimension())
      }
      window.addEventListener('resize', updateDimension);
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
    }, [screenSize])
  return (
    <div>
      <NavBar/>
      <GoUpButton screenSize={screenSize}/>
      <WelcomePage id='welcome' screenSize={screenSize}/>
      <CompetencesPage id='competences' screenSize={screenSize}/>
      <Realizations id='realizations' screenSize={screenSize}/>
      <ContactPage id='contact' screenSize={screenSize}/>
      <div className='linkToLegal'>
        <Link to={`/mentions_legales`}>
          <div className='divLinkLegalMentions'>
            <h2 onClick={() => {window.scrollTo(0, 0);}}className='linkLegalMentions'>Mentions Légales</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default MainPage;