import './RealizationCard.css'

function RealizationCard(props) {
    return (
          <div className="card">
            <div className="card__side card__side--front-1">
                <h4 className="card__heading">{props.title}</h4>
              <div className="card__details">
                <img src={props.img} alt={props.altImg} className='cardSizeImg'/>
              </div>
            </div>
            <div className="card__side card__side--back card__side--back-1">
                  <p className="cardBackTitle">{props.backTitle}</p>
                  <p className="cardBackDescription">{props.backDescription}</p>
            </div>
          </div>
    )
}

export default RealizationCard;