import './CompetenceCard.css'

function CompetenceCard (props)
{
    return (
    <div className={props.phone ? "container" : "containerPhone"}>
    <div className="box">
    <div className="content">
    <h3 className={props.phone ? 'titleCard' : 'titleCardPhone'}>{props.title}</h3>
    <img src={props.img} alt={props.altImg} className={props.phone ? 'sizeImg' : 'sizeImgPhone'}/>
    </div>
  </div>
  </div>
    )
}

export default CompetenceCard;