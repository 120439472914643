import './CompetencesPage.css'
import CompetenceCard from './CompetenceCard';
import webImg from './images/globe-grid.png'
import logicielImg from "./images/merger.png"
import applicationImg from "./images/app-development.png"
import webApp from "./images/adaptive.png"
import React, { useState, useEffect } from 'react'


function CompetencesPage (props) {
    return (
        <div className='backOfPage' id={props.id}>
            <h1 className='competencesTitle'>Mes Compétences</h1>
            <div className={props.screenSize.width >= 850 ? 'competencesCards' : 'competencesCardsPhone' }>
                <CompetenceCard title={'Développement et mise en ligne de sites vitrine'} img={webImg} altImg={'Web'} phone={props.screenSize.width >= 720}/>
                <CompetenceCard title={'Développement de logiciels et de solutions'} img={logicielImg} altImg={'logiciel'} phone={props.screenSize.width >= 720}/>
                <CompetenceCard title={"Développement d'applications android et IOS"} img={applicationImg} altImg={'phone'} phone={props.screenSize.width >= 720}/>
                <CompetenceCard title={'Développement de WebApps complètes'} img={webApp} altImg={'WebApp Responsive'} phone={props.screenSize.width >= 720}/>
            </div>
        </div>
    )
}

export default CompetencesPage;