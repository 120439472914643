import { KEY_VALUES } from "rsuite/esm/utils/constants";
import logo from "../../Pages/WelcomePage/Denni-Dev.png"
import "./LoginPage.css"
import { useState } from "react";

function LoginPage({ApiRoute, closeLogin, setToken})
{
    const [badParameters, setBadParameters] = useState(false);
    const [userPassword, setUserPassword] = useState({user_name : '', user_password : ''})
    const handleChange = (param, e) => {
        var newUserPassword = {...userPassword};
        newUserPassword[param] = e.target.value;
        setUserPassword(newUserPassword);
    }
    const handleConnection = () => {
        if (userPassword.user === '' || userPassword.user === '') {
            setBadParameters(true);
            return;
        }
        const requestOptions = {
            method : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body : JSON.stringify(userPassword)
        }
        fetch(`${ApiRoute}/login`, requestOptions)
        .then((response) => {
            if (response.status === 400) {
                setBadParameters(true);
                return
            }
            if (response.status === 200)
                return response.json()
            throw("Server error")
        })
        .then((data) => {
            if (data === undefined)
                return;
            setToken(data.token);
            closeLogin(false)
        })
        .catch((err) => {console.error(err);alert(err);alert("Erreur Serveur, merci de contacter contact@denni-dev.com")})
    }
    const handleKeyPressed = (e) => {
        if (e.key === "Enter")
            handleConnection();
    }
    return (
        <div className="baseLoginPage"  onKeyDown={(e) => handleKeyPressed(e)}>
            <div className="modalConnection">
                <img src={logo} alt="Logo de l'entreprise" className="logo"/>
                <div className="loginForm">
                    <input className="loginInput" onChange={handleChange.bind(this, 'user_name')} value={userPassword.user_name} placeholder="Utilisateur..."></input>
                    <input className="loginInput" onChange={handleChange.bind(this, 'user_password')} value={userPassword.user_password} placeholder="••••••••••••" type="password"></input>
                    <button className="loginButton" onClick={handleConnection}>Se connecter</button>
                </div>
                { badParameters && <h3 className="badParameters">Le nom d'Utilisateur ou le mot de passe est erroné !</h3>}
            </div>
        </div>
    )
}

export default LoginPage;