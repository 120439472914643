import './LegalMentions.css'
import GoUpButton from '../Pages/GoUpButton/GoUpButton';
import React, {useState, useEffect} from 'react';

function getCurrentDimension()
{
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
}

function LegalMentions()
{
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    useEffect(() => {
        const updateDimension = () => {
          setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return(() => {
            window.removeEventListener('resize', updateDimension);
        })
      }, [screenSize])
    return (
        <div id="top" className='baseLegalMentions'>
            <GoUpButton screenSize={screenSize}/>
            <div>
                <h1 className="legalMentionsh1">Mentions Légales</h1>
                <h2 className="legalMentionsh2">Identification du professionnel</h2>
                <p className='legalMentionsP'>Nom et Prénom : Denni Robin entrepreneur individuel</p>
                <p className='legalMentionsP'>Adresse : 34 rue de la Meurthe 68100 Mulhouse</p>
                <p className='legalMentionsP'>Numéro de téléphone de l'entreprise : 07.60.15.61.46</p>
                <p className='legalMentionsP'>Mail de l'entreprise : dennidevcontact@gmail.com</p>
                <p className='legalMentionsP'>Siret : 95397 141 300018</p>
                <p className='legalMentionsP'>Numéro d'immatriculation au RCS : 953 971 413</p>
                <p className='legalMentionsP'>Identitée de l'hébergeur du site : LWS, 2 Rue Jules Ferry, 88190 Golbey, 01 77 62 30 03</p>
            </div>
            <div className='sectionMentions'>
                <h1 className="legalMentionsh1">Politique de confidentialité</h1>
                <p className='legalMentionsP'>Denni-Dev accorde une importance toute particulière au droit à la vie privée des internautes et s'engage à protéger leurs données personnelles selon la législation française et européenne en vigueur. Aucune donnée personnelle n'est demandée aux visiteurs pour leur permettre de consulter le site Internet www.denni-dev.com.</p>
                <h2 className="legalMentionsh2">Identitée du responsable de traitement</h2>
                <p className='legalMentionsP'>Nom et Prénom : Denni Robin</p>
                <p className='legalMentionsP'>Adresse : 34 rue de la Meurthe 68100 Mulhouse</p>
                <p className='legalMentionsP'>Numéro de téléphone : 07.60.15.61.46</p>
                <p className='legalMentionsP'>Siret : 95397 141 300018</p>
                <h2 className='legalMentionsh2'>Portée et acceptation de la présente politique</h2>
                <p className='legalMentionsP'>La présente Politique s’applique uniquement aux informations collectées à partir de notre Site et ne s’applique pas aux informations collectées à partir d'autres sources, notamment, sans que cette liste ne soit limitée, Facebook®, Twitter®, YouTube® ou tout autre site ou réseaux social tiers.</p>
                <p className='legalMentionsP'>En utilisant notre Site, vous acceptez les termes de la présente Politique. Si vous refusez les termes de la présente Politique, nous vous invitons à ne pas utiliser notre Site ou à ne pas nous transmettre d’information à caractère personnel.</p>
                <h2 className='legalMentionsh2'>Données collectées sur notre site</h2>
                <p className='legalMentionsP'>Nous collectons et conservons certaines données à caractère personnel vous concernant (c’est-à-dire les informations qui permettent de vous identifier directement ou indirectement) ci-après « Données personnelles », et ce, uniquement lorsque vous les communiquez volontairement sur notre Site.</p>
                <p className='legalMentionsP'>Nous traitons notamment, vos nom, prénom, adresse électronique, etc.</p>
                <p className='legalMentionsP'>Nous pouvons être amenés à vous demander de nous communiquer des Données personnelles uniquement dans le formulaire de contact</p>
                <p className='legalMentionsP'>Lorsque vous naviguez sur notre Site, Denni-Dev ne collectera que les Données personnelles que vous nous fournissez volontairement, à l’exception de certains éléments de données à caractère personnel collectées automatiquement par les systèmes d’information et programmes utilisés pour le fonctionnement du Site et dont la transmission est inhérente à l’utilisation d’internet.</p>
                <h2 className='legalMentionsh2'>Finalitée de la collecte des données personnelles</h2>
                <p className='legalMentionsP'>Chaque formulaire présent sur le site restreint au strict nécessaire la collecte des données personnelles et indique systématiquement :</p>
                <p className='legalMentionsP'>- les finalités de la collecte des données personnelles</p>
                <p className='legalMentionsP'>- le caractère obligatoire ou facultatif des données</p>
                <p className='legalMentionsP'>En aucun cas Denni-Dev ne pourra utiliser ces données personnelles pour une finalité autre que celle(s) explicitement annoncée(s) sur le formulaire et acceptée(s) par l’internaute. De même, ces données personnelles ne pourront être transmises ou cédées à des tiers.</p>
                <h2 className='legalMentionsh2'>Destinataire des données personnelles</h2>
                <p className='legalMentionsP'>Les données personnelles collectées sur notre Site sont destinées au responsable de traitement ou aux autorités judiciaires, aux forces de l’ordre ou à toute autre autorité lorsque cela est requis par la loi.</p>
                <h2 className='legalMentionsh2'>Durée de conservation</h2>
                <p className='legalMentionsP'>Denni-Dev conservera vos Données personnelles pendant la durée nécessaire ou pour la durée requise par la loi en vigueur.</p>
                <h2 className='legalMentionsh2'>Vos droits</h2>
                <p className='legalMentionsP'>Conformément à la loi « Informatique et Libertés » du 6 janvier 1978 (art. 34), vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données qui vous concernent. Pour exercer ce droit, vous pouvez me contacter par mail à l’adresse dennidevcontact@gmail.com ou par courrier postal au 34 rue de la Meurthe 68100 Mulhouse</p>
                <p className='legalMentionsP'>Conformément à la réglementation en vigueur, votre demande doit être signée et accompagnée de la photocopie d’un titre d’identité portant votre signature et préciser l’adresse à laquelle doit vous parvenir la réponse. Une réponse vous sera alors adressée dans un délai de 2 mois suivant la réception de la demande.</p>
                <h2 className='legalMentionsh2'>Sécurité</h2>
                <p className='legalMentionsP'>La sécurité de vos Données personnelles est très importante pour nous. Les données personnelles recueillies sur le site www.denni-dev.com sont traitées selon des protocoles sécurisés qui limitent considérablement les risques d’interception ou de récupération par des tiers.</p>
                <p className='legalMentionsP'>Toutefois, en raison de la nature ouverte du réseau internet, nous ne pouvons pas exclure les actes de piratage, ou l’accès non autorisé des tiers. En continuant à utiliser notre Site, vous acceptez ces risques.</p>
                <p className='legalMentionsP'>Dans la limite de ce qui est autorisé par les lois en vigueur nous déclinons toute responsabilité en cas de dommage direct ou indirect, des pertes, coûts, que ce soit contractuelle, délictuelle, y compris par négligence, que vous pourriez subir en cas d’accès non autorisé, de perte ou d’altération de vos données personnelles.</p>
            </div>
        </div>
    )
}

export default LegalMentions;