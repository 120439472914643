import "./GoUpButton.css"
import { Link, animateScroll as scroll } from "react-scroll";
import upArrow from './up-arrow.png'

function GoUpButton(props) {
    return (
        <div className={props.screenSize.width >= 850 ? "upButtonCenter" : "upButtonCenterPhone"}>
            <Link activeClass="active" to="top" spy={true} smooth={true} offset={0} duration={300}>
                <img src={upArrow} alt={"Up Arrow"} className='upImg'/>
            </Link>
        </div>
    )
}

export default GoUpButton;